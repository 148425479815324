import {UpdateDeals, UpdateDealsCount, UpdateMostExpensiveDeal} from "./UpdateDeals";
import {UpdateAnsweredManagers, UpdateManagersCallsValues, UpdateOutgoingManagers} from "./UpdateAnsweredManagers";
import {UpdateMissedCalls} from "./UpdateCallsValues";
import {UpdateDashboardData} from "./UpdateDashboardData";

export default function Websocket() {
    const socket = new WebSocket("wss://dashboard.mdmprint.ru:8080");

    // Событие при подключении
    socket.onopen = () => {
        console.log("Соединение установлено");
        socket.send("Привет, сервер!");
    };

    // Событие при получении сообщения
    socket.onmessage = (event) => {
        console.log("Ответ от сервера:", event.data);
        if (event.data) {
            let data = JSON.parse(event.data);
            if (data.action) {
                if (data.action === 'b24new') {
                    UpdateDealsCount(data);
                }
                else if (data.action === 'b24working') {
                    UpdateMostExpensiveDeal(data);
                }
                else if (data.action === 'uis_answered') {
                    UpdateAnsweredManagers(data);
                }
                else if (data.action === 'uis_outgoing') {
                    UpdateOutgoingManagers(data);
                }
                else if (data.action === 'uis_finished') {
                    UpdateManagersCallsValues(data)
                }
                else if (data.action === 'uis_missed') {
                    UpdateMissedCalls(data);
                }
                else {
                    console.log('Action is wrong');
                }
            }
            else {
                console.log('Action was not sent');
            }
        }
    };

// Событие при закрытии соединения
    socket.onclose = () => {
        console.log("Соединение закрыто");
    };

// Событие при ошибке
    socket.onerror = (error) => {
        console.error("Ошибка:", error);
    };

    setInterval(function () {
        fetch('/get-updated-data')
            .then(response => response.json())
            .then(data => {
                UpdateDashboardData(data);
            });
    }, 60000);

}
