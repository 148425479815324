export function UpdateDashboardData(data) {
    let incomingCallsValue = document.querySelector('.incoming-calls__value');
    let missedCallsValue = document.querySelector('.missed-calls__value');
    let dealsValue = document.querySelector('.deals-count__value');
    let prevDealsValue = document.querySelector('.deals-count-prev__value');
    let expOrderValue = document.querySelector('.expensive-order__value');
    let expOrderName = document.querySelector('.expensive-order-manager__value');

    if (incomingCallsValue) {
        incomingCallsValue.innerHTML = data.deals.incoming_calls;
    }

    if (missedCallsValue) {
        missedCallsValue.innerHTML = data.deals.missed_calls;
    }

    if (dealsValue) {
        dealsValue.innerHTML = data.deals.deal_count;
    }

    if (prevDealsValue) {
        prevDealsValue.innerHTML = 'Вчера: ' + data.deals.deal_count_prev;
    }

    if (expOrderValue) {
        expOrderValue.innerHTML = data.deals.most_expensive_deal.toLocaleString('ru-RU') + ' р';
    }

    if (expOrderName) {
        expOrderName.innerHTML = data.deals.most_expensive_deal_person;
    }

    if (data.managers && data.managers.length) {
        for (let manager of data.managers) {
            let managerItem = document.querySelector('.managers-item[data-id="' + manager.id + '"]');

            if (managerItem) {
                let callIds =
                    manager.incoming_call_id && manager.outgoing_call_id ?
                        `${manager.incoming_call_id},${manager.outgoing_call_id}` :
                        manager.incoming_call_id || manager.outgoing_call_id;
                managerItem.dataset.callId = callIds;

                managerItem.querySelector('.dashboard-item__value').innerHTML =
                    manager.incoming_calls + ' / ' + manager.outgoing_calls;

                if (manager.can_see_call || manager.is_talking) {
                    managerItem.classList.add('__active');
                }
                else {
                    managerItem.classList.remove('__active');
                }
            }
        }
    }
}
